import { useLocation } from "@reach/router";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import { graphql } from "gatsby";
import { parse } from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import slugify from "slugify";
import Layout from "../components/layout";
import FeaturedItem from "../components/partials/featured-item";
import { LinerStyles } from "../components/partials/liner-styles";
import Measure from "../components/sections/measure";
import OurProcess from "../components/sections/process";
import OurProcessLiners from "../components/sections/process-liners";
import Seo from "../components/seo";
import months from "../data/months";
import { zipCodes } from "../data/zip-codes";
import SimpleProductTemplate from "./simple-product";
import VariableProductTemplate from "./variable-product";
import { SHIPPING_PRICES } from "../context/cart-context";

const COMPANY_NAME_SHORT = process.env.COMPANY_NAME_SHORT;

const ProductTemplate = ({ data }) => {
  const currentProduct = data.wpProduct;

  const location = useLocation();
  const search = parse(location.search);
  const galleryRef = useRef(null);
  const [images, setImages] = useState([]);
  const [seoTitle, setSeoTitle] = useState(currentProduct.seo?.title);
  const [seoDescription, setSeoDescription] = useState(
    currentProduct.seo?.metaDesc
  );

  useEffect(() => {
    let item = {
      Title: data.wpProduct.title,
      ItemId: data.wpProduct.product.sku,
      Categories: data.wpProduct.product.category,
      ImageUrl: data.wpProduct.featuredImage.node.localFile.publicURL,
      Url: `/${data.wpProduct.slug}/`,
      Metadata: {
        Price:
          data.wpProduct.product.salePrice || data.wpProduct.product.basePrice,
      },
    };

    if (typeof window !== "undefined" && window.hasOwnProperty("_learnq")) {
      window._learnq.push(["track", "Viewed Product", item]);
      window._learnq.push(["trackViewedItem", item]);
    }
    // eslint-disable-next-line
  }, []);

  const fullScreenGallery = () => {
    if (document.querySelector(".image-gallery-content.fullscreen")) {
      const index = galleryRef.current.getCurrentIndex() + 1;
      galleryRef.current.slideToIndex(index);
    } else {
      galleryRef.current.fullScreen();
    }
    return true;
  };

  useEffect(() => {
    const newImages = [];
    data.wpProduct.product.imageGallery.map((image) => {
      const thisImage = {
        original: image.localFile.publicURL,
        thumbnail: image.localFile.publicURL,
        originalAlt: image.title,
        thumbnailAlt: image.title,
        originalClass: "flex items-center justify-center",
      };
      return newImages.push(thisImage);
    });
    setImages(newImages);
  }, [data]);

  const updateVariation = (variation) => {
    if (variation.styleSeoTitle) {
      setSeoTitle(variation.styleSeoTitle);
    }
    if (variation.styleSeoDescription) {
      setSeoDescription(variation.styleSeoDescription);
    }
  };

  const handleUpdateGallery = (gallery) => {
    const newImages = [];
    gallery.map((image) => {
      const thisImage = {
        original: image.localFile.publicURL,
        thumbnail: image.localFile.publicURL,
        originalAlt: image.title,
        thumbnailAlt: image.title,
        originalClass: "flex items-center justify-center",
      };
      return newImages.push(thisImage);
    });
    setImages(newImages);
  };

  const lastDay = lastDayOfMonth(new Date());
  const difference = differenceInCalendarDays(lastDay, new Date());
  const saleEnds = {
    date: `${months[lastDay.getMonth()]} ${lastDay.getDate()}`,
    days: difference,
  };

  let extraCost = 0;

  if (search.size) {
    const size = decodeURI(search.size);
    extraCost = data.wpProduct.product.sizes.find((item) => {
      return item.size === size;
    })?.extraCost;
  }

  const basePrice = currentProduct.product.basePrice + extraCost;
  const salePrice = currentProduct.product.salePrice
    ? currentProduct.product.salePrice + extraCost
    : undefined;

  const highestExtraCost =
    currentProduct.product?.sizes?.reduce((acc, item) => {
      if (item.extraCost > acc) {
        return item.extraCost;
      }
      return acc;
    }, 0) || 0;

  const productPrice = salePrice ? salePrice : basePrice;

  const offerShippingDetails = [
    {
      "@context": "https://schema.org",
      "@type": "OfferShippingDetails",
      deliveryTime: {
        "@type": "ShippingDeliveryTime",
        businessDays: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            "https://schema.org/Monday",
            "https://schema.org/Tuesday",
            "https://schema.org/Wednesday",
            "https://schema.org/Thursday",
            "https://schema.org/Friday",
          ],
        },
        cutoffTime: "12:00:15Z",
        handlingTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 2,
          unitCode: "d",
        },
        transitTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 5,
          unitCode: "d",
        },
      },
      shippingRate: {
        "@type": "MonetaryAmount",
        value: SHIPPING_PRICES[COMPANY_NAME_SHORT][0] ?? 0.0,
        currency: "USD",
      },
      shippingDestination: zipCodes[0].zipCodes.map((zip) => ({
        "@type": "DefinedRegion",
        addressCountry: "US",
        postalCode: zip,
      })),
    },
    {
      "@context": "https://schema.org",
      "@type": "OfferShippingDetails",
      deliveryTime: {
        "@type": "ShippingDeliveryTime",
        businessDays: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            "https://schema.org/Monday",
            "https://schema.org/Tuesday",
            "https://schema.org/Wednesday",
            "https://schema.org/Thursday",
            "https://schema.org/Friday",
          ],
        },
        cutoffTime: "12:00:15Z",
        handlingTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 2,
          unitCode: "d",
        },
        transitTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 5,
          unitCode: "d",
        },
      },
      shippingRate: {
        "@type": "MonetaryAmount",
        value: SHIPPING_PRICES[COMPANY_NAME_SHORT][1] ?? 0.0,
        currency: "USD",
      },
      shippingDestination: zipCodes[1].zipCodes.map((zip) => ({
        "@type": "DefinedRegion",
        addressCountry: "US",
        postalCode: zip,
      })),
    },
    {
      "@context": "https://schema.org",
      "@type": "OfferShippingDetails",
      deliveryTime: {
        "@type": "ShippingDeliveryTime",
        businessDays: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            "https://schema.org/Monday",
            "https://schema.org/Tuesday",
            "https://schema.org/Wednesday",
            "https://schema.org/Thursday",
            "https://schema.org/Friday",
          ],
        },
        cutoffTime: "12:00:15Z",
        handlingTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 2,
          unitCode: "d",
        },
        transitTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 5,
          unitCode: "d",
        },
      },
      shippingRate: {
        "@type": "MonetaryAmount",
        value: SHIPPING_PRICES[COMPANY_NAME_SHORT][2] ?? 0.0,
        currency: "USD",
      },
      shippingDestination: zipCodes[2].zipCodes.map((zip) => ({
        "@type": "DefinedRegion",
        addressCountry: "US",
        postalCode: zip,
      })),
    },
  ];

  const offers =
    currentProduct.product.type === "simple"
      ? {
          "@type": "Offer",
          priceCurrency: "USD",
          price: `${salePrice ? salePrice : basePrice}`,
          url: `${process.env.PUBLIC_URL}/${currentProduct.slug}/`,
          availability: "https://schema.org/InStock",
          priceValidUntil: lastDay.toISOString(),
          shippingDetails: offerShippingDetails,
          availableAtOrFrom: {
            "@context": "https://schema.org",
            "@type": "Place",
            name: process.env.COMPANY_NAME,
            branchCode: process.env.GOOGLE_PLACE_BRANCH_CODE,
          },
        }
      : {
          "@type": "AggregateOffer",
          lowPrice: `${productPrice}`,
          highPrice: `${productPrice + highestExtraCost}`,
          priceCurrency: "USD",
          priceValidUntil: lastDay.toISOString(),
          offerCount: currentProduct.product?.sizes?.length || 0,
        };
  let models =
    currentProduct.product.type === "simple"
      ? {}
      : currentProduct.product.sizes.map((value) => {
          const extraCost = value.extraCost || 0;
          const productUrl = new URL(
            `${process.env.PUBLIC_URL}/${currentProduct.slug}`
          );
          const sku = value.size
            ? `${currentProduct.slug}-${slugify(value.size)}`.replace(
                "I-don't-know-my-size",
                "unknown-size"
              )
            : currentProduct.slug;
          productUrl.searchParams.append("size", value.size);
          return {
            "@type": "ProductModel",
            name: `${currentProduct.title} - ${value.size}`,
            description: `${currentProduct.seo.metaDesc} - ${value.size}`,
            sku,
            brand: process.env.COMPANY_NAME,
            image: `${process.env.PUBLIC_URL}${currentProduct.featuredImage.node.localFile.publicURL}`,
            url: productUrl.toString(),
            offers: {
              "@type": "Offer",
              price: (salePrice ? salePrice : basePrice) + extraCost,
              priceCurrency: "USD",
              name: `${currentProduct.title} - ${value.size}`,
              availability: "https://schema.org/InStock",
              priceValidUntil: lastDay.toISOString(),
              sku,
              shippingDetails: offerShippingDetails,
              url: productUrl.toString(),
            },
          };
        });

  return (
    <Layout>
      <Seo title={seoTitle} description={seoDescription} />
      <Helmet>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "${currentProduct.title}",
            "image": "${process.env.PUBLIC_URL}${
          currentProduct.featuredImage.node.localFile.publicURL
        }",
            "description": "${currentProduct.seo.metaDesc}",
            "sku": "${currentProduct.product.sku}",
            "brand": "${process.env.COMPANY_NAME}",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.2",
                "reviewCount": "39"
            },
            "offers": ${JSON.stringify(offers)},
            "model": ${JSON.stringify(models)}
        }`}</script>
      </Helmet>
      <div className="flex flex-wrap mb-8 lg:mb-8 lg:mt-12 max-w-3xl mx-auto">
        <div className="p-0 lg:px-4 w-full lg:w-1/2">
          <ImageGallery
            items={images}
            lazyLoad={true}
            showNav={false}
            showPlayButton={false}
            showBullets={false}
            autoPlay={false}
            showFullscreenButton={true}
            useBrowserFullscreen={false}
            onClick={() => {
              fullScreenGallery();
            }}
            ref={galleryRef}
          />
          {currentProduct.product.featuredItem && (
            <FeaturedItem currentProduct={currentProduct} />
          )}
        </div>
        <div className="p-4 md:p-2 md:pl-8 w-full lg:w-1/2 mx-auto xl:m-0 mb-8 text-grey-darker">
          {currentProduct.product.type === "simple" ? (
            <SimpleProductTemplate
              currentProduct={currentProduct}
              saleEnds={saleEnds}
              processImg={data.file}
            />
          ) : (
            <VariableProductTemplate
              currentProduct={currentProduct}
              saleEnds={saleEnds}
              processImg={data.file}
              updateGallery={handleUpdateGallery}
              updateVariation={updateVariation}
            />
          )}
        </div>
      </div>
      {currentProduct.product.category[0] === "liners" && (
        <>
          <LinerStyles />
          <OurProcessLiners />
        </>
      )}
      {currentProduct.product.category[0] === "covers" && <OurProcess />}
      <Measure product={currentProduct.product} />
    </Layout>
  );
};

export const productQuery = graphql`
  query ($id: String!) {
    wpProduct(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      slug
      featuredImage {
        node {
          title
          localFile {
            publicURL
          }
        }
      }
      product {
        mainH1
        basePrice
        salePrice
        sku
        category
        type
        taxable
        sizes {
          size
          extraCost
        }
        styles {
          style
          styleDescription
          styleGallery {
            title
            localFile {
              publicURL
            }
          }
          styleSeoTitle
          styleSeoDescription
          outOfStock
          outOfStockShortDescription
          outOfStockLongDescription
        }
        imageGallery {
          title
          localFile {
            publicURL
          }
        }
        shortLabel
        sizeGuideText
        sizeGuideImage {
          altText
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        featuredItem
        featuredItemTitle
        featuredItemDescription
        featuredItemImage {
          altText
          title
          localFile {
            publicURL
          }
        }
        featuredItemLinkSlug
        featuredItemLinkText
        featuredItemFootnote
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "order-process.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`;

export default ProductTemplate;
